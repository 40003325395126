<template>
    <span class="is-size-4" :class="color">
        {{ parsedAnswer }}
    </span>
</template>
<script>
export default {
    
    props: {
        answer: {
            type: [String, Number]
        },
        builder: {
            type: Object,
            default: () => ({})
        }
    },

    computed: {
        castAnswer() {
            return this.answer ? Number(this.answer) : null
        },
        parsedAnswer() {
            if(!this.answer) {
                return 'Incomplete'
            }
            switch(this.castAnswer) {
                case 0: return this.builder.failure_label
                case 1: return this.builder.ok_label
                case -1: return this.builder.na_label
                default: 'Incomplete'
            }
            return 'Incomplete'
        },
        color() {
            switch(this.castAnswer) {
                case 0: return 'has-text-danger'
                case 1: return 'has-text-success'
                case -1: return ''
                default: ''
            }

            return ''
        }
    }

}
</script>